export const calculateBalance = (ledgerDetails) => {
  let balance = 0;
  let totalReceipt = 0;
  let totalPayment = 0;
  const paymentDetailsWithBalance = ledgerDetails.map((item, index) => {
    if (item.receipt > 0) {
      balance -= item.receipt;
      totalReceipt += item.receipt;
    }
    if (item.payment > 0) {
      balance += item.payment;
      totalPayment += item.payment;
    }
    return { ...item, balance };
  });
  return paymentDetailsWithBalance;
};


export const calculateTotalSale = (saleDetail) => {
  let totalSales = 0;
  saleDetail.forEach((item) => {
    totalSales += item.totalAmount;
  });
  return totalSales;
};

export const calculateFinalBalanceCashBook = (details) => {
  let finalBalance = 0;
  let paymentTotal = 0;
  let receiptTotal = 0;

  details.forEach((item) => {
    finalBalance += item.balance;
    paymentTotal += item.payment;
    receiptTotal += item.receipt;
  });
  return { finalBalance, paymentTotal, receiptTotal };
};

export const calculateFinalBalance = (details) => {
  let finalBalance = 0;
  let paymentTotal = 0;
  let receiptTotal = 0;

  details.forEach((item) => {
    finalBalance += item.balance;
    paymentTotal += item.credit;
    receiptTotal += item.debit;
  });
  return { finalBalance, paymentTotal, receiptTotal };
};

export const calculateTotalAmount = (ledgerDetails, selectedBranch) => {
  let totalAmount = 0;

  ledgerDetails.forEach((product) => {
    const quantity = selectedBranch
      ? product?.distribution.find(
        (branch) => branch.branchName === selectedBranch
      )?.itemQuantity || 0
      : product?.distribution.reduce(
        (totalQuantity, branch) => totalQuantity + branch.itemQuantity,
        0
      );

    const avgPrice = parseFloat(product.averagePrice).toFixed(2);
    const itemAmount = quantity * avgPrice;
    totalAmount += itemAmount;
  });

  return totalAmount.toFixed(2);
};

export const calculateQuoteTotal = (ledgerDetails) => {
  let total = 0;
  ledgerDetails.forEach((product) => {
    const itemTotal =
      product.itemQuantity * product.productPrice - product.productDiscount;
    total += itemTotal;
  });
  return total;
};

export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const numberToWords = (num) => {
  var a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen ",
  ];
  var b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  if ((num = num.toString()).length > 9) return "overflow";
  var n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
      : "";
  return str + "Rupees only ";
};


export const calculateTrialBalance = (ledgerDetails) => {
  let totalReceipt = 0;
  let totalPayment = 0;


  ledgerDetails.map((item, index) => {
    if (parseInt(item.balance) >= 0) {
      totalReceipt += parseInt(item.balance);
    }
    if (parseInt(item.balance) < 0) {
      totalPayment += parseInt(item.balance);
    }
  });
  return totalPayment, totalReceipt;
};
